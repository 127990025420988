import BaseButton from '@components/BaseButton/BaseButton';
import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import BookingSteps from '@components/BookingSteps/BookingSteps';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { isEmail } from '@utils/index';
// import { isEmail, randomString } from '@utils/index';
// import { Upload$ } from '@utils/uploadS3/uploadS3';
// import { validateFile, preprocessImage } from '@utils/uploadS3/upload';
// import { MEDIA_SIZE } from '@consts/consts';
// import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

import { useMemoizedFn } from 'ahooks';
import { message, Radio } from 'antd';
// import { message, Radio, Upload } from 'antd';
import { post } from '@utils/request';
import { useState, useEffect } from 'react';
import bgAppointment from '../../images/bgAppointment.png';
import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { Currency, ConsultationMethod, Gender } from '@consts/enums';

// import type { UploadRequestOption } from 'rc-upload/lib/interface';
// import { UPLOAD } from '@consts/consts';
// import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
// import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
// import { getIdCardUploadToken } from '@services/uploadService';
// import FiledDatePicker from './components/FiledDatePicker';
// import FiledDayAndMonthPicker from './components/FiledDayAndMonthPicker';
// import FiledYearPicker from './components/FiledYearPicker';
// import type { DatePickerProps } from 'antd';
// import dayjs from 'dayjs';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import alertTerms from '@utils/globalComponents/TermsAndPrivacyAlert/TermsAlert';
import alertPrivacy from '@utils/globalComponents/TermsAndPrivacyAlert/ProvacyAlert';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
import { UUTUBE_TYPE, CFF_TYPE, COVID_TYPE } from '@consts/consts';
// import {
//   RcFile,
//   // UploadChangeParam,
//   // UploadFile,
// } from 'antd/lib/upload/interface';

const accountTypeText = {
  phoneNumber: 'Contact number',
  email: 'Email',
};

type AccountTypeText = typeof accountTypeText;

// const borderColor = {
//   default: '#D5D4DC',
//   warning: '#EF8943',
//   error: '#C83532',
//   success: '#2BAC47',
// };

// TODO 获取用户信息=>看看怎么样
export default function FillPersonalInfos() {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view appointment',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const { productType, isHaveSelectResultType, selectResultType } = useSelectResultType();
  // const mediaSize = useMediaQuery();
  // const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  const navigate = useLocalNavigate();
  const [firstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [firstNameCn, setFirstNameCn] = useState('');
  const [lastNameCn, setLastNameCn] = useState('');
  const [gender, setGender] = useState(Gender.Male);
  const [email, setEmailName] = useState('');
  const [HKID, setHKID] = useState('');
  const [method, setMethod] = useState<keyof AccountTypeText>('email');
  const [consultType, setConsultType] = useState<number>(0);

  // const [dobDayAndMonth, setDobDayAndMonth] = useState('');
  // const [dobYear, setDobYear] = useState('');
  const [dayOfBirth, setDayOfBirth] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [boxValue, setBoxValue] = useState(false);
  const [agreeBoxValue, setAgreeBoxValue] = useState(false);

  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();
  // const [uploadFile, setUploadFile] = useState<any>();

  // const [fileObj, setFileObj] = useState<any>();
  const [isSubmitCheck, setIsSubmitCheck] = useState(false);

  // console.log(dayOfBirth, 'dayOfBirth');

  const handleCheckFirstName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.firstNameCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckLastName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.LastNameCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );
  const handleCheckHKID: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.HKIDCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckDay: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.DateNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckMonth: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.MonthCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckYear: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.YearCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckEmail: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('FillInfosPage.EmailCanNotBeEmpty') as string);
      } else if (isEmail(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('FillInfosPage.invaldEmail') as string);
      }
    },
  );

  // const handleCheckDayOfBirth: FiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.DayOfBirthCanNotBeEmpty') as string);
  //     } else if (val) {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.invaldDate') as string);
  //     }
  //   },
  // );

  // const handleCheckDobYear: FiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.DayOfBirthCanNotBeEmpty') as string);
  //     } else if (val) {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.invaldDate') as string);
  //     }
  //   },
  // );

  // const handleCheckDobDayAndMonth: FiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.DayOfBirthCanNotBeEmpty') as string);
  //     } else if (val) {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus('error');
  //       setHelpText(t('FillInfosPage.invaldDate') as string);
  //     }
  //   },
  // );

  // const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
  //   setDayOfBirth(dateString);
  // };

  // const onDobYearChange: DatePickerProps['onChange'] = (date, dateString) => {
  //   setDobYear(dateString);
  // };

  // const onDobDayAndMonthChange: DatePickerProps['onChange'] = (date, dateString) => {
  //   setDobDayAndMonth(dateString);
  // };

  const [buttonLoading, setButtonLoading] = useState(false);

  // const [HKIDImgURL, setHKIDImgURL] = useState('');
  // const customUpload = async (option: any) => {
  //   let file = option.file as File;

  //   // TODO 把option的file进行替换
  //   // preprocessImage

  //   if (file.type.includes('image') && file.type !== 'image/gif') {
  //     try {
  //       if (file.type.includes('heic') || file.type.includes('heif')) {
  //         const newfileBolb = await import('heic2any').then((heic2any) => {
  //           return heic2any.default({
  //             blob: file,
  //             toType: 'image/png',
  //             quality: 1,
  //           });
  //         });
  //         file = new File(Array.isArray(newfileBolb) ? newfileBolb : [newfileBolb], 'img.png', {
  //           type: 'image/png',
  //         });
  //       }

  //       const cutFile = await preprocessImage(file, UPLOAD.MAX_IMAGE_WIDTH, UPLOAD.MAX_IMAGE_HEIGHT, 0.5);
  //       option = {
  //         ...option,
  //         file: cutFile as Blob,
  //       };
  //       // option.file = cutFile;
  //       console.log(cutFile, 'cutFile');
  //     } catch (err: any) {
  //       console.log(err?.message);
  //       return;
  //     }
  //   }
  //   // 经过压缩之后的图片
  //   const newFile = option.file as File;

  //   // TODO 格式转换=>需要再看一下怎么转换【github再看一下格式转换】

  //   const errorMessage = validateFile(newFile);
  //   console.log(errorMessage, 'errorMessage');
  //   errorMessage && option.onError && option.onError(new Error(errorMessage || 'upload error'));
  //   if (errorMessage) {
  //     return;
  //   }
  //   const { sts, bucket, path } = await getIdCardUploadToken();
  //   const randomFileName = `${randomString()}${new Date().getTime()}.${file.type.replace('image/', '')}`;
  //   await Upload$(
  //     {
  //       AccessKeyId: sts.Credentials.AccessKeyId,
  //       SecretAccessKey: sts.Credentials.SecretAccessKey,
  //       SessionToken: sts.Credentials.SessionToken,
  //     },
  //     bucket,
  //     path + '/' + randomFileName,
  //     option,
  //   ).catch((e) => console.warn(e));
  //   setHKIDImgURL(path + '/' + randomFileName);
  // };

  const onBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setBoxValue(e.target.checked);
  });

  const onAgreeBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setAgreeBoxValue(e.target.checked);
  });

  const getBookingParams = (
    answers?: {
      questionId: string;
      questionAnswerId: string;
    }[],
  ) => {
    if (!isHaveSelectResultType) {
      return null;
    }
    let bookingParams = {
      bookingData: {
        doctorUserId: handleLocalStorage.get(website.step_doctor_id),
        timeslotDate: handleLocalStorage.get(website.step_timeslot_date),
        timeslotId: handleLocalStorage.get(website.step_timeslot_id),
        // TODO amount和currency需要再讨论一下
        // TODO amount，前端不用传【FE需要移除】
        currency: Currency.hkd,
        consultType: consultType,
        fromProductType: productType,
        testResult: selectResultType,
      },
      personalInformation: {
        firstName: firstName,
        lastName: LastName,
        firstNameCn: firstNameCn,
        lastNameCn: lastNameCn,
        gender: gender,
        email: method !== 'email' ? email || handleLocalStorage.get(website.email) : undefined,
        phone: method === 'email' ? phoneNumber || handleLocalStorage.get(website.phone_number) : undefined,
        dob: dayOfBirth.split('/').reverse().join('-'),
        hkid: consultType === ConsultationMethod.Online ? HKID : undefined,
        // identity: consultType === ConsultationMethod.Online ? HKIDImgURL : undefined,
        receiveMarketing: boxValue ? 1 : 0,
      },
    };
    if (productType === UUTUBE_TYPE) {
      (bookingParams as any).questionnaire = {
        answers: answers,
      };
    } else if ([CFF_TYPE, COVID_TYPE].includes(productType)) {
    } else {
      return null;
    }

    return bookingParams;
  };

  const handleSubmitUserInfos: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();
      // TODO: 需要与Eather确定是否会产生影响
      // const isUploaded = !!(uploadFile?.fileList && uploadFile?.fileList.length > 0);
      track({
        event_type: 'book appointment',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
          'first name': firstName,
          'last name': LastName,
          email: email,
          'contact number': phoneNumber,
          'day of birth': dayOfBirth,
          'ID/passport number': HKID,
          // 'ID uploaded': consultType === ConsultationMethod.Online ? isUploaded : 'in-clinic none',
          'terms conditions and privacy policy': agreeBoxValue,
          'marketing materials': boxValue,
        },
      });
      if (!agreeBoxValue) {
        message.error(<span id="fillInfos__policy-error">{t('FillInfosPage.agreeWarningText')}</span>);
        return;
      }

      let canSubmit;

      // if (!uploadFile) {
      //   setFileObj({
      //     helpText: t('FillInfosPage.uploadWarningText'),
      //     status: 'error',
      //   });
      //   // 展示upload报错信息
      // } else if (uploadFile?.fileList && uploadFile?.fileList.length === 0) {
      //   // 展示upload报错信息
      //   setFileObj({
      //     helpText: t('FillInfosPage.uploadWarningText'),
      //     status: 'error',
      //   });
      // } else {
      //   // 不展示报错信息
      //   setFileObj({
      //     helpText: null,
      //     status: null,
      //   });
      // }

      // 必填项进行Check
      setIsSubmitCheck(true);
      // Offline不需要上传HKID等内容
      // 用户只用填写一个值
      if (consultType === ConsultationMethod.Online) {
        canSubmit = !isNaN(gender) && firstName && LastName && dayOfBirth && HKID && (isPhoneNumberValid || email);
      } else {
        canSubmit = !isNaN(gender) && firstName && LastName && dayOfBirth && (isPhoneNumberValid || email);
      }
      // const canSubmit = firstName && LastName && dayOfBirth && HKID && (isPhoneNumberValid || email);
      if (!canSubmit) {
        message.error(t('FillInfosPage.eachItemWarningText'));
        return;
      }
      try {
        setButtonLoading(true);
        // TODO 进行接口的请求
        // let params: any = [];
        // const qArr = [q1, q2, q3, q4];
        // qArr.map((val) => {
        //   params.push(formatData(val));
        // });
        // saveConsultationMethod(params);

        // TODO 还缺一下s3的token
        const localStorageAnswers = handleLocalStorage.get(website.answers);

        let answers: {
          questionId: string;
          questionAnswerId: string;
        }[] = [];

        localStorageAnswers &&
          localStorageAnswers.map((obj: any) => {
            answers.push({
              questionId: obj?.id,
              questionAnswerId: obj?.answer,
            });
          });
        // 如果是另外两个type，就不传answers，这个需要处理一下
        const params = getBookingParams(answers);

        handleLocalStorage.set(website.fill_params, params || null);

        const data = await post('/care/booking', params);
        setTimeout(() => {
          navigate(
            `/payments?intent_id=${data.intentId}${
              isHaveSelectResultType ? `&selectResultType=${selectResultType}` : ''
            }`,
          );
          setButtonLoading(false);
        }, 1000);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = Array.isArray(responseMessage) ? responseMessage[0] : responseMessage;
        if (err?.response?.data?.statusCode === '403' || err?.response?.data?.statusCode === 403) {
          // const responseMessage = err?.response?.data?.message;
          // const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'Please login!';
          message.error(errorMessage || 'Please login!');
          navigate('/login');
          return;
        }

        setButtonLoading(false);
        message.error(errorMessage || 'error!');
      }
    });

  // const customRequest = async (option: any) => {
  //   track({
  //     event_type: 'click choose file',
  //     event_properties: {
  //       'booking id': getDataTrackConstantId('BookingId'),
  //     },
  //   });
  //   customUpload(option);
  // };

  useEffect(() => {
    // liber生成的，取这个值就好了
    const _consultType = parseInt(handleLocalStorage.get(website.step_consult_type));

    setMethod(handleLocalStorage.get('method'));
    setConsultType(_consultType);
  }, []);

  useEffect(() => {
    if (day && day.length > 0 && month && month.length > 0 && year && year.length > 0) {
      setDayOfBirth(`${day}/${month}/${year}`);
    } else {
      setDayOfBirth('');
    }
  }, [day, month, year]);

  const beforeunload = useMemoizedFn((ev: any) => {
    const showModal = firstName || LastName || dayOfBirth || HKID || phoneNumber || email;
    if (showModal) {
      const returnValue = 'Are you sure you would like to leave? Your progress will not be saved';
      ev.returnValue = returnValue;
      return returnValue;
    } else {
      return;
    }
  });

  // const onUploadChange = (val: any) => {
  //   setUploadFile(val);
  // };

  // const renderHelpTextIcon = () => {
  //   if (!fileObj?.helpText || !fileObj?.status) {
  //     return null;
  //   }
  //   if (fileObj?.status === 'success') {
  //     return (
  //       <CheckCircleFilled
  //         className={'text-[16px] sm:text-[16px]'}
  //         style={{
  //           color: borderColor[fileObj?.status as keyof typeof borderColor],
  //         }}
  //       />
  //     );
  //   } else {
  //     return (
  //       <ExclamationCircleFilled
  //         className={'text-[16px] sm:text-[16px]'}
  //         style={{
  //           color: borderColor[fileObj?.status as keyof typeof borderColor],
  //         }}
  //       />
  //     );
  //   }
  // };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunload);
    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [beforeunload]);

  return (
    <div className="relative flex w-full justify-center text-[#212121]">
      <div className="absolute top-0 left-0 w-full">
        <img className="w-full" src={bgAppointment} />
      </div>
      <div className="relative lg:w-[1280px] w-full px-[24px] lg:px-0 lg:py-[68px] lg:rounded-[30px] lg:mt-[13.3vw] lg:mb-[76px] pb-[62px] bg-[#ffffff]">
        <BookingSteps step={4} />
        <p className="lg:text-[16px] text-[16px] lg:mt-0 mt-[29px]  lg:text-center text-left">
          {t('FillInfosPage.fillDesc')}
        </p>
        <div className="lg:w-[762px] m-auto">
          <div className="grid lg:grid-cols-2 lg:gap-x-[18px]">
            <div>
              <FiledInput
                className="lg:w-[372px]"
                value={firstName}
                onChange={setFirstName}
                title={t('FirstName')}
                placeholder={t('FirstName')}
                onCheckValid={handleCheckFirstName}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__firstName-input"
                errorId="fillInfos__firstName-error"
              />
              <div className="lg:w-[762px] m-auto">
                <div className="font-[400] text-[12px] text-[#98979B] leading-[18px] tracking-[0.2px] mt-[4px] lg:text-[12px]  lg:leading-[18px] lg:tracking-[0.2px] lg:mt-[4px]">
                  {t('FillInfosPage.FirstNameInfo')}
                </div>
              </div>
            </div>
            <FiledInput
              className="lg:w-[372px]"
              value={LastName}
              onChange={setLastName}
              title={t('LastName')}
              placeholder={t('LastName')}
              onCheckValid={handleCheckLastName}
              isSubmitCheck={isSubmitCheck}
              id="fillInfos__LastName-input"
              errorId="fillInfos__LastName-error"
            />
            <div>
              <FiledInput
                required={false}
                className="lg:w-[372px]"
                value={firstNameCn}
                onChange={setFirstNameCn}
                title={t('FirstNameCn')}
                placeholder={t('FirstNameCn')}
                onCheckValid={handleCheckFirstName}
                isSubmitCheck={false}
                id="fillInfos__firstName-input"
                errorId="fillInfos__firstName-error"
              />
              <div className="lg:w-[762px] m-auto">
                <div className="font-[400] text-[12px] text-[#98979B] leading-[18px] tracking-[0.2px] mt-[4px] lg:text-[12px]  lg:leading-[18px] lg:tracking-[0.2px] lg:mt-[4px]">
                  {t('FillInfosPage.FirstNameInfo')}
                </div>
              </div>
            </div>
            {/* Input the name as printed on your HKID or passport */}
            <FiledInput
              required={false}
              className="lg:w-[372px]"
              value={lastNameCn}
              onChange={setLastNameCn}
              title={t('LastNameCn')}
              placeholder={t('LastNameCn')}
              onCheckValid={handleCheckLastName}
              isSubmitCheck={false}
              id="fillInfos__LastName-input"
              errorId="fillInfos__LastName-error"
            />
          </div>
          <FiledTitle title={t('GenderAB')} />
          <Radio.Group
            onChange={(e) => {
              setGender(e.target.value);
            }}
            value={gender}
          >
            <Radio value={Gender.Male} id="fillInfos_gender_male">
              <div className="sm:mr-[59px] font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                {t('Male')}
              </div>
            </Radio>
            <Radio value={Gender.Female} id="fillInfos_gender_female" className="email-select-radio">
              <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                {t('Female')}
              </div>
            </Radio>
          </Radio.Group>
          {method !== 'email' && (
            <FiledInput
              value={email}
              onChange={setEmailName}
              onCheckValid={handleCheckEmail}
              title={t('Email')}
              placeholder={t('FillInfosPage.EnterValidEmailAddress')}
              isSubmitCheck={isSubmitCheck}
              id="fillInfos__email-input"
              errorId="fillInfos__email-error"
            />
          )}
          {method === 'email' && (
            // TODO phone需要做校验
            <div className="lg:w-[762px] m-auto">
              <FiledTitle title={t('loginPage.ContactNumber')} />
              {/*  isSubmitCheck={isSubmitCheck} */}
              <PhoneNumberInput
                wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[8px] lg:text-[16px] text-[16px] lg:w-[762px] md:h-[48px] lg:h-[43px] border-[2px] flex items-center lg:px-[15px] px-[15px]"
                value={phoneNumber}
                onChange={setPhoneNumber}
                onIsValidChange={handleIsPhoneNumberValidChange}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__phoneNumber-input"
                errorId="fillInfos__phone-error"
              />
            </div>
          )}
          {/* <FiledDatePicker
            value={dayOfBirth || ''}
            title={t('DateOfBirth') || ''}
            onChange={onDateChange}
            // filedDatePickerClassName={'mt-[29px] lg:mt-[45px]'}
            isSubmitCheck={isSubmitCheck}
            onCheckValid={handleCheckDayOfBirth}
            // style={{
            //   marginTop: pickDropSelectStatus === PickDropSelectType[PickDropSelectType.PickUp] ? '0px' : undefined,
            // }}
            placeholder="DD/MM/YYYY"
            // defaultValue={sampleCollectionDate}
            forceDisabledDate={true}
            // disabledStartDate={dayjs().subtract(1, 'month').format('DD/MM/YYYY')}
            disabledEndDate={dayjs().subtract(1, 'days').format('DD/MM/YYYY')}
            id="fillInfos__dayOfBirth-input"
            errorId="fillInfos__dayOfBirth-error"
          /> */}
          <div className="lg:w-[762px] m-auto">
            <FiledTitle title={t('DateOfBirth') || ''} />
            <div className="flex w-full gap-[8px] lg:gap-[8px]">
              <FiledInput
                title={null}
                className="w-[33%]"
                value={day}
                onChange={(val: string) => {
                  if (val.length > 2) {
                    setDay(val.slice(0, 2));
                  } else {
                    setDay(val);
                  }
                }}
                placeholder={t('FillInfosPage.DatePlaceHolder')}
                onCheckValid={handleCheckDay}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__day-input"
                errorId="fillInfos__day-error"
                number={true}
              />
              <FiledInput
                title={null}
                className="w-[33%]"
                value={month}
                onChange={(val: string) => {
                  if (val.length > 2) {
                    setMonth(val.slice(0, 2));
                  } else {
                    setMonth(val);
                  }
                }}
                // onChange={setMonth}
                placeholder={t('FillInfosPage.MonthPlaceHolder')}
                onCheckValid={handleCheckMonth}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__month-input"
                errorId="fillInfos__month-error"
                number={true}
              />
              <FiledInput
                title={null}
                className="w-[33%]"
                value={year}
                onChange={(val: string) => {
                  if (val.length > 4) {
                    setYear(val.slice(0, 4));
                  } else {
                    setYear(val);
                  }
                }}
                // onChange={setYear}
                placeholder={t('FillInfosPage.YearPlaceHolder')}
                onCheckValid={handleCheckYear}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__year-input"
                errorId="fillInfos__year-error"
                number={true}
              />
            </div>
          </div>

          {/* <FiledYearPicker
            value={dobYear || ''}
            title={'dobYear' || ''}
            onChange={onDobYearChange}
            // filedDatePickerClassName={'mt-[29px] lg:mt-[45px]'}
            isSubmitCheck={isSubmitCheck}
            onCheckValid={handleCheckDobYear}
            // onCheckValid={handleCheckDayOfBirth}
            // style={{
            //   marginTop: pickDropSelectStatus === PickDropSelectType[PickDropSelectType.PickUp] ? '0px' : undefined,
            // }}
            placeholder="YYYY"
            // defaultValue={sampleCollectionDate}
            // forceDisabledDate={true}
            // disabledStartDate={dayjs().subtract(1, 'month').format('DD/MM/YYYY')}
            // disabledEndDate={dayjs().subtract(1, 'days').format('DD/MM/YYYY')}
            id="fillInfos__dobYear-input"
            errorId="fillInfos__dobYear-error"
          /> */}

          {/* <FiledDayAndMonthPicker
            value={dobDayAndMonth || ''}
            title={'dobDayAndMonth' || ''}
            onChange={onDobDayAndMonthChange}
            // filedDatePickerClassName={'mt-[29px] lg:mt-[45px]'}
            isSubmitCheck={isSubmitCheck}
            onCheckValid={handleCheckDobDayAndMonth}
            // onCheckValid={handleCheckDayOfBirth}
            // style={{
            //   marginTop: pickDropSelectStatus === PickDropSelectType[PickDropSelectType.PickUp] ? '0px' : undefined,
            // }}
            placeholder="DD/MM"
            // defaultValue={sampleCollectionDate}
            // forceDisabledDate={true}
            // disabledStartDate={dayjs().subtract(1, 'month').format('DD/MM/YYYY')}
            // disabledEndDate={dayjs().subtract(1, 'days').format('DD/MM/YYYY')}
            id="fillInfos__dobDayAndMonth-input"
            errorId="fillInfos__dobDayAndMonth-error"
          /> */}
          {consultType === ConsultationMethod.Online && (
            <>
              <FiledInput
                value={HKID}
                onChange={setHKID}
                title={t('HKID/PassportNumber')}
                placeholder={t('HKID/PassportNumberPlaceHolder')}
                onCheckValid={handleCheckHKID}
                isSubmitCheck={isSubmitCheck}
                id="fillInfos__HKID-input"
                errorId="fillInfos__HKID-error"
              />
              <div className="lg:w-[762px] m-auto">
                <div className="font-[400] text-[12px] text-[#98979B] leading-[18px] tracking-[0.2px] mb-[34px] mt-[4px] lg:text-[12px]  lg:leading-[18px] lg:tracking-[0.2px] lg:mt-[4px] lg:mb-[50px]">
                  {t('FillInfosPage.HKIDInfo')}
                </div>
              </div>
            </>
          )}
          {/* {consultType === ConsultationMethod.Online && (
            <div className="lg:w-[762px] m-auto">
              <FiledTitle title={t('FillInfosPage.UploadIdentityDocument')} />
              {isLg && <p className="lg:text-[14px] text-[14px] lg:mb-[25px]">{t('FillInfosPage.ImgSizeLimitText')}</p>}

              <Upload
                accept="image/jpg,image/jpeg,image/png,image/heic,image/heif"
                customRequest={customRequest}
                onChange={onUploadChange}
                onRemove={() => setHKIDImgURL('')}
                maxCount={1}
                // beforeUpload={handleBeforeUpload}
              >
                <BaseButton
                  buttonClassName="bg-[#2DBDB6] lg:w-[116px] lg:h-[32px] w-[120px] text-[14px] h-[48px]"
                  value={t('FillInfosPage.ChooseFile')}
                  id="fillInfos__chooseFile-button"
                />
              </Upload>

              {fileObj?.helpText && (
                <div className="flex items-center lg:mb-[16px]" id="fillInfos__upload-error">
                  {renderHelpTextIcon()}
                  <span
                    className="text-[14px] ml-[4px]"
                    style={{
                      color: borderColor[(fileObj?.status as keyof typeof borderColor) || 'default'],
                    }}
                  >
                    {fileObj?.helpText}
                  </span>
                </div>
              )}
              {!isLg && (
                <p className="lg:text-[14px] text-[14px] lg:mb-[25px] mt-[17px]">
                  {t('FillInfosPage.ImgSizeLimitText')}
                </p>
              )}
            </div>
          )} */}
          <div className="lg:w-[762px] m-auto flex lg:items-center items-baseline lg:mt-[31px] mt-[35px] lg:mb-[17px] mb-[15px]  leading-[24px] lg:leading-[24px]">
            <BaseCheckbox onChange={onAgreeBoxChange} id="fillInfos__agreePrivacy-checkbox" />
            <span className="text-[#212121] lg:text-[14px] text-[14px] lg:ml-[22px] ml-[22px] leading-[24px] lg:leading-[24px]  w-[314px] lg:w-[auto]">
              {t('FillInfosPage.agree1')}{' '}
              <span
                className="text-[#2DBDB6] lg:text-[14px] text-[14px] leading-[24px] cursor-pointer font-[500] lg:leading-[24px]"
                onClick={() => {
                  alertTerms('fillInfos__close-Terms');
                }}
                id="fillInfos__click-Terms"
              >
                {t('FillInfosPage.agree2')}
              </span>{' '}
              {t('FillInfosPage.agree3')} {/* TODO: 这里的跳转应该需要调整，需要跟zach确认 */}
              <span
                id="fillInfos__click-policy"
                onClick={() => {
                  alertPrivacy('fillInfos__close-policy');
                }}
                className="text-[#2DBDB6] lg:text-[14px] text-[14px] leading-[24px] cursor-pointer font-[500] lg:leading-[24px]"
              >
                {t('FillInfosPage.agree4')}
              </span>{' '}
              <span className="text-[#FF0000] lg:text-[14px] text-[14px] leading-[24px] lg:leading-[24px]">*</span>
            </span>
          </div>
          <div className="lg:w-[762px] m-auto flex lg:items-center items-baseline lg:mt-[0px] mt-[0px] lg:mb-[36px] mb-[34px]  leading-[24px] lg:leading-[24px]">
            <BaseCheckbox onChange={onBoxChange} id="fillInfos__notWishReceive-checkbox" />
            <span className="text-[#212121] lg:text-[14px] text-[14px] leading-[24px] lg:leading-[24px] text lg:ml-[22px] ml-[22px] w-[305px] lg:w-[auto]">
              {t('FillInfosPage.notWishReceiveText')}
            </span>
          </div>
          <div className="lg:w-[762px] m-auto">
            <BaseButton
              buttonClassName="bg-[#2DBDB6] lg:w-[326px] w-[366px] h-[50px] lg:h-[50px]"
              value={t('FillInfosPage.BookAppointment')}
              onClick={handleSubmitUserInfos}
              loading={buttonLoading}
              id="fillInfos__bookAppointment-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
